<template>
    <div class="card card-product accordion-panel">
        <div class="card-header">
            <h1 class="card-title">
                <i class="far fa-life-ring fa-fw"></i>
                {{ accordionData.title }}
            </h1>
        </div>

        <div class="card-body">
            <div class="accordion-group">
                <Accordion-Card
                    v-for="(item, index) in accordionData.items"
                    :key="index"
                    :index="index"
                    :item="item"
                    :layout="item.layout"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        accordionData: {
            type: Object,
            required: true,
            default() {
                return {};
            },
        },
    },
};
</script>
