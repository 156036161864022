<template>
    <div>
        <!-- Step by Step Guide Button -->
        <button class="btn btn-info btn-help main-area-heading-align" @click="toggleOverlay">
            <i class="fa fa-fw fa-life-ring"></i> Step-by-Step Video Guide
            <span class="new-feature"><i class="fa fa-star" aria-hidden="true"></i></span>
        </button>

        <!-- Step by Step Guide Overlay -->
        <div id="step-by-step-overlay" :class="{ 'easein-transition': show }">
            <!-- Sidebar -->
            <div id="step-by-step-sidebar" class="card card-product">
                <div class="card-header">
                    <div class="card-title-wrapper">
                        <div class="card-title">
                            <h3 class="margin-top-base margin-bottom-base">
                                <i class="fa fa-life-ring fa-fw"></i> Step-by-Step Video Guide
                            </h3>
                        </div>
                        <button class="btn btn-default btn-sm" @click="toggleOverlay">
                            <i class="fa fa-times-circle-o fa-lg"></i> Close
                        </button>
                    </div>
                </div>

                <div class="card-body card-sidebar">
                    <svg x="0px" y="0px" width="30px" :height="svgHeight">
                        <path
                            class="path-notch-v"
                            fill="#a2a2a0"
                            stroke-width="2"
                            stroke="#a2a2a0"
                            d="M15 0 V1000"
                        ></path>
                    </svg>

                    <div
                        v-for="(guide, index) in guides"
                        class="btn btn-default btn-block"
                        :class="{ 'btn-product-color selected': guide.tagId === selectedGuideTag }"
                        @click="selectGuide(guide)"
                    >
                        <div class="card-guide">
                            <div class="card-guide-notch">
                                <span class="fa-stack">
                                    <span class="fa fa-circle fa-stack-2x"></span>
                                    <span class="fa fa-circle-thin fa-stack-2x"></span>
                                    <strong class="fa-stack-1x">{{ index + 1 }}</strong>
                                </span>
                            </div>
                            <div class="card-guide-info">
                                <span>{{ guide.title }}</span>
                            </div>
                        </div>
                    </div>

                    <div v-if="guides.length === 0" class="no-pupils-warning margin-top-md-1 margin-bottom-md-1">
                        <h3 class="card-title">
                            <i class="fa fa-exclamation-triangle fa-lg"></i>
                            No resources have been assigned. Please contact the help desk if this message persists.
                        </h3>
                    </div>
                </div>
            </div>

            <!-- Modal -->
            <div id="step-by-step-modal" ref="modalElement" class="card card-product-alt">
                <div class="card-header">
                    <div class="card-title-wrapper">
                        <h1 class="card-title">{{ selectedGuide.title }}</h1>
                        <button class="btn btn-default btn-sm" @click="toggleOverlay">
                            <i class="fa fa-times-circle-o fa-lg"></i> Close
                        </button>
                    </div>
                </div>

                <div class="card-body">
                    <div id="video-wrapper" class="col-12">
                        <video-player :options="videoOptions"></video-player>
                    </div>

                    <div class="col-12">
                        <hr>
                        <ul class="list-unstyled">
                            <li v-for="file in selectedGuide.files" class="margin-bottom-sm-3">
                                <a :href="`/manage/resources/${file.id}`" target="_blank">
                                    <i class="fa fa-fw fa-file-pdf-o"></i>
                                    <span>{{ file.title }}</span>
                                </a>
                            </li>
                        </ul>
                        <p class="text-info">
                            <small><i class="fa fa-fw fa-info-circle"></i> PDFs open in a new browser tab.</small>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    /**
     * The component's parameters.
     */
    props: {
        guides: {
            type: Array,
            default() {
                return [];
            },
        },
    },

    /**
     * The component's data object.
     */
    data() {
        return {
            // Overlay guide shown / not shown.
            show: false,

            // The selected guide to display.
            selectedGuide: null,

            // The tag id of the selected guide.
            selectedGuideTag: null,

            // The id of the page we are on.
            pageTagId: 123, // tktktk - HARDCODED

            // Options for the videojs player.
            videoOptions: {
                controls: true,
                autoplay: false,
                preload: 'none',
                fluid: true,
                sources: [],
            },
        };
    },

    /**
     * The component's computed properties
     */
    computed: {
        // Height in pixes of the vertical SVG line.
        svgHeight() {
            const SVG_GAP = 79; // The gap between each number circle node (in pixels)
            const GUIDES_NUM = this.guides.length;

            if (GUIDES_NUM === 0) {
                return '0px';
            }

            // Subtract 1 - not displaying line after last node - multiplay by gap length
            const height = (GUIDES_NUM - 1) * SVG_GAP;
            return `${height}px`;
        },
    },

    /**
     * The component watches for data changes and reacts.
     */
    watch: {
        // React to changes on the show data property.
        show(value) {
            // Clear any existing timeouts.
            let timer;
            window.clearTimeout(timer);

            // Toggle the scrollbar on the body element.
            document.getElementsByTagName('body')[0].style.overflow = value ? 'hidden' : 'scroll';

            if (value === false) {
                // Reset the video and selected guide
                this.setDefaultGuide();

                // Hide the modal.
                this.$refs.modalElement.style.transform = 'translate(-120%, -50%)';

                // Wait 600ms
                timer = window.setTimeout(() => {
                    this.$refs.modalElement.removeAttribute('style');
                }, 600);
            }
        },
    },

    /**
     * Called right before the mounting begins.
     */
    created() {
        // Determine which guide to pre-select based on the page the user is on.
        this.setDefaultGuide();
    },

    /**
     * The component's custom methods.
     */
    methods: {
        // Hide show the overlay.
        toggleOverlay() {
            this.show = !this.show;
        },

        // Set the guide which corresponds to the page the user is on.
        setDefaultGuide() {
            this.selectedGuide = this.guides.find(guide => guide.tagId === this.pageTagId);
            this.selectedGuideTag = this.selectedGuide.tagId;

            // Remove any existing video sources.
            if (this.videoOptions.sources.length > 0) {
                this.videoOptions.sources.pop();
            }

            // Add new video sources.
            this.videoOptions.sources.push(this.selectedGuide.video);
        },

        // Assign the selected guide
        selectGuide(guide) {
            // Clear any existing timeouts.
            let timer;
            window.clearTimeout(timer);

            // Hide the model
            this.$refs.modalElement.style.transform = 'translate(-120%, -50%)';

            // Update the guide tag id.
            this.selectedGuideTag = guide.tagId;

            // Wait 800ms for transition before updating video source.
            timer = window.setTimeout(() => {
                this.selectedGuide = guide;

                // Remove any existing video sources.
                if (this.videoOptions.sources.length > 0) {
                    this.videoOptions.sources.pop();
                }

                // Add new video sources.
                this.videoOptions.sources.push(this.selectedGuide.video);

                // Show the modal.
                this.$refs.modalElement.style.transform = 'translate(0%, -50%)';
            }, 800);
        },
    },
};
</script>
