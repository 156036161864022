<template>
    <div class="card accordion-card">
        <div
            class="list-group-item"
            :class="{ 'list-group-item--expanded': expanded }"
            @click="toggleCard"
        >
            <h2 class="mb-0">
                <i class="fas fa-fw fa-caret-right" :class="{ 'caret-rotate': expanded }"></i>
                <strong v-if="showIndex">Step {{ index + 1 }} &dash;</strong>
                <span>{{ item.title }}</span>
            </h2>
        </div>

        <transition name="accordion">
            <div v-show="expanded" class="guide-sub-list">
                <div class="row guide-sub-content">
                    <!-- Text Only Layout -->
                    <div v-if="layout === 'text-only'" class="col-12" v-html="item.body"></div>

                    <!-- Video and Files -->
                    <template v-if="layout === 'video-files'">
                        <div class="col-12 col-lg-6">
                            <Video-Player :options="videoOptions" :expanded="expanded" />
                        </div>

                        <div class="col-12 col-lg-6 margin-top-sm-3 d-flex flex-column justify-content-between">
                            <ul class="resources-list">
                                <li v-for="file in item.files">
                                    <a
                                        :href="`${config.resource_path}/${file.id}`"
                                        target="_blank"
                                        title="PDF opens in a new browser tab"
                                    >
                                        <i class="far fa-fw fa-file-pdf"></i>
                                        <span>{{ file.displayName }}</span>
                                    </a>
                                </li>
                            </ul>

                            <p class="text-info">
                                <i class="fas fa-fw fa-info-circle"></i> PDFs open in a new browser tab.
                            </p>
                        </div>
                    </template>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        // Position of accordion card step
        index: {
            type: Number,
            required: true,
        },

        // Data for the accordion card
        item: {
            type: Object,
            required: true,
            default() {
                return {};
            },
        },

        // The layout for the accordion card
        layout: {
            type: String,
            required: true,
            default: 'layout-1',
        },

        // Configuration options
        config: {
            type: Object,
            default() {
                return {
                    'resource_path' : '/manage/resources',
                    'show_index': false
                };
            },
        },
    },

    data() {
        return {
            // Accordion open/closed.
            expanded: false,

            // Options for the videojs player.
            videoOptions: {
                controls: true,
                autoplay: false,
                preload: 'none',
                fluid: true,
                sources: [
                    {
                        // src: `${this.config.resource_path}/${this.item.video.id}`,
                        src: 'https://vjs.zencdn.net/v/oceans.mp4',
                        type: 'video/mp4',
                    },
                ],
            },
        };
    },

    computed: {
        caret() {
            return this.expanded ? 'fa-caret-down' : 'fa-caret-right';
        },
        showIndex() {
            return this.config.show_index ? this.config.show_index : false;
        }
    },

    methods: {
        /**
         * Toggle opening and closing of the accordion.
         */
        toggleCard() {
            this.expanded = !this.expanded;
        },
    },
};
</script>

<style lang="scss">
    .accordion-card {
        h2 {
            font-size: 1rem;
            font-weight: 400;
        }
    }

    .accordion-enter-active,
    .accordion-leave-active {
        transition: max-height .25s ease-out;
    }

    .accordion-enter, 
    .accordion-leave-to {
        max-height: 0;
    }
</style>
